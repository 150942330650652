/*
 * @Author: zhangjingqing
 * @Date: 2022-02-10 22:54:32
 * @LastEditors: zhangjinqing
 * @LastEditTime: 2022-07-28 20:26:27
 * @Description: file content
 * @FilePath: \lcdp-front-web\src\store\modules\user.js
 */
import Vue from "vue";
import { login, queryLoginUsrInfo } from "@/api/login";
import {
  querySystemTenantid,
  querySystemTenantidExtendList,
  queryExtendFieldServiceList,
} from "@/api/tenant";
import { ACCESS_TOKEN } from "@/store/mutation-types";

const user = {
  state: {
    token: "",
    userInfo: {},
    tenantInfo: {},
    fieldInfo: {},
    permissionList: [],
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_USER_INFO: (state, userInfo) => {
      state.userInfo = userInfo;
    },
    SET_TENANT_INFO: (state, tenantInfo) => {
      state.tenantInfo = tenantInfo;
    },
    SET_FIELD_INFO: (state, fieldInfo) => {
      state.fieldInfo = fieldInfo;
    },
  },

  actions: {
    Login({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo)
          .then((response) => {
            if (response.status == "200") {
              const result = response.data;
              Vue.ls.set(ACCESS_TOKEN, result.token, 1 * 24 * 60 * 60 * 1000);
              commit("SET_TOKEN", result.token);
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    Logout() {
      return new Promise((resolve) => {
        localStorage.clear();
        location.reload();
        resolve();
      });
    },

    GetUserInfo({ dispatch, commit }) {
      return new Promise((resolve, reject) => {
        queryLoginUsrInfo()
          .then((response) => {
            if (response.status == "200") {
              const result = response.data;
              Vue.ls.set("USER_INFO", result, 1 * 24 * 60 * 60 * 1000);
              commit("SET_USER_INFO", result);
              if (result.tenantId) {
                dispatch("GetTenantInfo", result.tenantId);
                dispatch("GetFieldInfo", result.tenantId);
              }
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    GetTenantInfo({ commit }, tenantid) {
      return new Promise((resolve, reject) => {
        querySystemTenantid({ id: tenantid })
          .then((response) => {
            if (response.status == "200") {
              const result = response.data;
              Vue.ls.set("TENANT_INFO", result, 1 * 24 * 60 * 60 * 1000);
              commit("SET_TENANT_INFO", result);
              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    GetFieldInfo({ commit }, tenantid) {
      return new Promise((resolve, reject) => {
        querySystemTenantidExtendList({
          tenantId: tenantid,
          page: 1,
          pageSize: 20,
        })
          .then((response) => {
            if (response.status == "200") {
              queryExtendFieldServiceList({
                tenantid: tenantid,
                page: 1,
                pageSize: 20,
              }).then((response1) => {
                if (response1.status == "200") {
                  const dicts = response1.data.rows;
                  const result = response.data;
                  let rows = result.rows;
                  let fieldInfo = {};
                  rows.forEach((row) => {
                    let key = dicts.filter(
                      (item) => item.id == row.expandFieldId
                    )[0].expandFieldName;
                    fieldInfo[key] = row.expandFieldValue;
                  });
                  Vue.ls.set("FIELD_INFO", fieldInfo, 1 * 24 * 60 * 60 * 1000);
                  commit("SET_FIELD_INFO", fieldInfo);
                }
              });

              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};

export default user;
