/*
 * @Author: zhangjingqing
 * @Date: 2022-02-22 21:34:44
 * @LastEditors: zhangjinqing
 * @LastEditTime: 2022-08-10 21:30:16
 * @Description: file content
 * @FilePath: \lcdp-front-web\src\utils\utils.js
 */
export function getLabel(
  val,
  list = [],
  useVal = "value",
  exportVal = "label"
) {
  let label = "";
  for (let i = 0; i < list.length; i++) {
    if (val == list[i][useVal]) {
      label = list[i][exportVal];
    }
  }
  if (!label && label != 0) {
    label = val;
  }
  return label;
}

//查找父节点
export function getParents(tree, id) {
  var temp = [];
  var forFn = function (arr, id) {
    for (var i = 0; i < arr.length; i++) {
      var item = arr[i];
      if (item.id === id) {
        temp.push(item);
        forFn(tree, item.parentId);
        break;
      } else {
        if (item.children) {
          forFn(item.children, id);
        }
      }
    }
  };
  forFn(tree, id);
  return temp;
}

// 数字转字母
export function number_to_word(x) {
  let s = "";
  while (x > 0) {
    let m = x % 26;
    m = m === 0 ? (m = 26) : m;
    s = String.fromCharCode(96 + parseInt(m)) + s;
    x = (x - m) / 26;
  }
  return s.toUpperCase();
}

export function sortChinese(arr, dataLeven) {
  // 参数：arr 排序的数组; dataLeven 数组内的需要比较的元素属性
  /* 获取数组元素内需要比较的值 */
  function getValue(option) {
    // 参数： option 数组元素
    if (!dataLeven) return option;
    var data = option;
    dataLeven.split(".").filter(function (item) {
      data = data[item];
    });
    return data + "";
  }
  arr.sort(function (item1, item2) {
    return getValue(item1).localeCompare(getValue(item2), "zh-CN");
  });
}

export function timeFix() {
  const time = new Date();
  const hour = time.getHours();
  return hour < 9
    ? "早上好"
    : hour <= 11
    ? "上午好"
    : hour <= 13
    ? "中午好"
    : hour < 20
    ? "下午好"
    : "晚上好";
}

export function getQueryString(name) {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
  var r = window.location.search.substr(1).match(reg);
  if (r != null) {
    return decodeURIComponent(r[2]);
  }
  return "";
}

export function decode(code) {
  let len = code.length;
  let result = "";
  for (let i = 0; i < len / 2; i++) {
    let step1 = code.substr(2 * i, 2);
    let step2 = Number(step1) - i - 1;
    result += String.fromCharCode(step2);
    if (i == 3 || i == 5) result += "-";
    else if (i == 7) result += " ";
    else if (i == 9 || i == 11) result += ":";
  }
  return result;
}
