<!--
 * @Author: zhangjingqing
 * @Date: 2022-03-11 23:06:14
 * @LastEditors: zhangjinqing
 * @LastEditTime: 2022-06-19 18:59:27
 * @Description: file content
 * @FilePath: \lcdp-front-web\src\layouts\TaxLayout.vue
-->
<template>
  <a-layout style="height: 100%; min-width: 1600px">
    <a-layout-header class="header" @click="hidePanel">
      <img class="logo" :src="require('@/assets/img/m1.png')" alt="" />
      <div class="header-content">
        <div class="left">
          <img :src="require('@/assets/img/p6.png')" alt="" />
          <a-dropdown>
            <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
              {{ tenantInfo.name }} <a-icon type="down" />
            </a>
            <!-- <a-menu slot="overlay">
              <a-menu-item>
                <a href="javascript:;">少林寺旅游经济特区管理委员会</a>
              </a-menu-item>
              <a-menu-item>
                <a href="javascript:;">五台山旅游经济特区管理委员会</a>
              </a-menu-item>
              <a-menu-item>
                <a href="javascript:;">泰山旅游经济特区管理委员会</a>
              </a-menu-item>
            </a-menu> -->
          </a-dropdown>
        </div>
        <div class="right">
          <a style="margin-right: 40px">下载客户端</a>
          <a><img :src="require('@/assets/img/message1.png')" alt="" />消息</a>
          <a><img :src="require('@/assets/img/help.png')" alt="" />帮助</a>
          <a href="javascript:;" @click="handleLogout"
            ><img :src="require('@/assets/img/exit.png')" alt="" />退出</a
          >
        </div>
      </div>
    </a-layout-header>
    <div class="content-cover" @click="hidePanel">
      <a-layout class="content">
<!--        <a-layout-sider width="240" class="aside">-->
<!--          <div class="tabs">-->
<!--            <div class="tab" v-for="(item, i) in tabs" :key="item.name">-->
<!--              <div class="cover" @click="handleTabChange(i, item.route)">-->
<!--                <div class="img">-->
<!--                  <img :src="i == active ? item.icon : item.icon1" alt="" />-->
<!--                </div>-->
<!--                <div class="title">{{ item.name }}</div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="userInfo">-->
<!--            <div class="cover">-->
<!--              <div class="avatar">-->
<!--                <a-avatar :size="120" icon="user" />-->
<!--              </div>-->
<!--              <div class="name">{{ userInfo.realname }}</div>-->
<!--              <div class="dep">{{ tenantInfo.name }}</div>-->
<!--              <div class="setting">-->
<!--                <a-->
<!--                  href="javascript:;"-->
<!--                  ref="setting"-->
<!--                  @click="settingVisible = !settingVisible"-->
<!--                  >设置</a-->
<!--                >-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div-->
<!--            class="setting-panel"-->
<!--            ref="settingPanel"-->
<!--            @click.stop="settingVisible = true"-->
<!--            v-if="settingVisible"-->
<!--          >-->
<!--            <div class="tip">-->
<!--              <img :src="require('@/assets/img/tip.png')" alt="" />-->
<!--              <span-->
<!--                >请谨慎选择会计准则，设置生效后，再次调整需联系010-6223457。</span-->
<!--              >-->
<!--            </div>-->
<!--            <a-form-->
<!--              :form="form"-->
<!--              :layout="'vertical'"-->
<!--              style="margin-top: 50px; padding: 0 20px"-->
<!--            >-->
<!--              <a-form-item label="会计准则">-->
<!--                <a-select-->
<!--                  v-model="fieldInfo.indexStandardType"-->
<!--                  placeholder="请选择会计准则"-->
<!--                >-->
<!--                  <a-select-option value="0">-->
<!--                    企业会计准则（2007）-->
<!--                  </a-select-option>-->
<!--                  <a-select-option value="1">-->
<!--                    企业会计准则（2019）-->
<!--                  </a-select-option>-->
<!--                  <a-select-option value="2">-->
<!--                    小企业会计准则（2013）-->
<!--                  </a-select-option>-->
<!--                </a-select>-->
<!--              </a-form-item>-->
<!--              <a-form-item label="纳税人类型">-->
<!--                <a-radio-group v-model="fieldInfo.taxpayerNature">-->
<!--                  <a-radio value="0"> 一般纳税人 </a-radio>-->
<!--                  <a-radio value="1"> 小规模企业纳税人 </a-radio>-->
<!--                </a-radio-group></a-form-item-->
<!--              >-->
              <!-- <a-form-item style="display: flex; justify-content: flex-end">
                <a-button
                  type="primary"
                  style="border-radius: 15px; width: 80px"
                  >生效</a-button
                >
              </a-form-item> -->
<!--            </a-form>-->
<!--          </div>-->
<!--        </a-layout-sider>-->
        <a-layout class="main">
          <div class="cover">
            <router-view />
          </div>
        </a-layout>
      </a-layout>
    </div>
  </a-layout>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "TaxLayout",
  data() {
    return {
      tabs: [
        // {
        //   name: "数据采集",
        //   icon: require("@/assets/img/sa1.png"),
        //   icon1: require("@/assets/img/sa7.png"),
        //   route: "/taxRiskControl/dataCollect",
        // },
        // {
        //   name: "申报检测",
        //   icon: require("@/assets/img/sa4.png"),
        //   icon1: require("@/assets/img/sa3.png"),
        //   route: "/taxRiskControl/reportAnalyse",
        // },
        // {
        //   name: "风险检测",
        //   icon: require("@/assets/img/sa5.png"),
        //   icon1: require("@/assets/img/sa2.png"),
        //   route: "/taxRiskControl/riskAnalyse",
        // },
      ],
      active: 0,
      settingVisible: false,
      form: {},
    };
  },
  mounted() {
    this.active = this.$route.name == "riskAnalyse" ? 1 : 0;
  },
  methods: {
    ...mapActions(["Logout"]),
    handleLogout() {
      this.Logout();
    },
    handleTabChange(i, route) {
      this.active = i;
      this.$router.push(route);
    },
    hidePanel(e) {
      if (
        e.target != this.$refs.setting &&
        e.target != this.$refs.settingPanel
      ) {
        this.settingVisible = false;
      }
    },
  },
  computed: {
    ...mapGetters(["fieldInfo", "userInfo", "tenantInfo"]),
  },
  watch: {
    $route() {
      this.active = this.$route.name == "riskAnalyse" ? 1 : 0;
    },
  },
};
</script>
<style lang="less" scoped>
@side-width: 240px;

.header {
  height: 60px;
  padding: 0 25px;
  background: #fff;
  box-shadow: 0px 0px 6px #cccccc;
  z-index: 9;
  .logo {
    margin: 10px 28px 14px 0;
    float: left;
  }
  .header-content {
    display: flex;
    justify-content: space-between;
    height: 100%;
    .left {
      img {
        margin-bottom: 4px;
        margin-right: 5px;
      }
      .ant-dropdown-link {
        color: #383838;
      }
    }
    .right {
      a {
        color: #383838;
        margin-left: 30px;
        &:hover {
          color: @primary-color;
        }
        img {
          margin: 0 10px 5px 0;
        }
      }
    }
  }
}
.content-cover {
  height: calc(100% - 60px);
  overflow-y: auto;
  .content {
    height: 100%;
    background: #f9f9fc;
    .aside {
      min-height: 850px;
      height: 100%;
      position: relative;
      background: #fff;
      .tabs {
        width: @side-width;
        .tab {
          display: flex;
          justify-content: center;
          align-items: center;
          width: @side-width;
          height: @side-width;
          .cover {
            cursor: pointer;
            .img {
              width: 100px;
              height: 100px;
              border-radius: 24px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            .title {
              display: block;
              width: 100px;
              height: 40px;
              line-height: 40px;
              font-size: 16px;
              text-align: center;
              color: #383838;
            }
          }
        }
      }
      .userInfo {
        position: absolute;
        bottom: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: @side-width;
        .cover {
          .avatar {
            margin-bottom: 20px;
          }
          .name,
          .dep,
          .setting {
            width: 120px;
            text-align: center;
            margin-bottom: 20px;
          }
          .name {
            font-size: 16px;
            font-weight: 900;
          }
        }
      }
    }
    .setting-panel {
      background: #fff;
      position: absolute;
      right: -400px;
      bottom: 0;
      width: 400px;
      height: 450px;
      z-index: 100;
      padding: 30px;
      box-shadow: 0px 0px 9px #cccccc;
      transition: all 1s;

      .tip {
        display: flex;
        justify-content: space-between;
        img {
          width: 40px;
          height: 40px;
        }
        span {
          margin-left: 10px;
          margin-top: 10px;
          color: #383838;
        }
      }
    }
    .main {
      min-height: 850px;
      height: 100%;
      overflow-y: scroll;
      background: #202441;
      .cover {
        margin: 20px;
      }
    }
  }
}
</style>
