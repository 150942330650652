/*
 * @Author: zhangjingqing
 * @Date: 2022-02-10 22:54:32
 * @LastEditors: zhangjinqing
 * @LastEditTime: 2022-06-24 20:27:47
 * @Description: file content
 * @FilePath: \lcdp-front-web\src\router\router.config.js
 */
import {
  UserLayout,
  BasicLayout,
  TaxLayout,
  RouteView,
  // AppLayout,
} from "@/layouts";

export const appMap = [
  {
    path: "/",
    name: "taxRiskControl",
    redirect: "/taxRiskControl",
  },
  {
    path: "/taxRiskControl",
    name: "taxRiskControl",
    component: TaxLayout,
    redirect: "/taxRiskControl/dataCollect",
    hidden: true,
    children: [
      {
        path: "/taxRiskControl/dataCollect",
        name: "dataCollect",
        meta: { title: "数据采集" },
        component: () =>
          import(
            /* webpackChunkName: "user" */ "@/views/taxRiskControl/dataCollect/Index"
          ),
      },
      {
        path: "/taxRiskControl/riskAnalyse",
        name: "riskAnalyse",
        meta: { title: "风险检测" },
        component: () =>
          import(
            /* webpackChunkName: "user" */ "@/views/taxRiskControl/riskAnalyse/Index"
          ),
      },
      {
        path: "/taxRiskControl/reportAnalyse",
        name: "reportAnalyse",
        meta: { title: "申报检测" },
        component: () =>
          import(
            /* webpackChunkName: "user" */ "@/views/taxRiskControl/reportAnalyse/Index"
          ),
      },
    ],
  },
  {
    path: "/taxRiskControl/riskAnalyse/report",
    name: "riskReport",
    meta: { title: "检测报告" },
    component: () =>
      import(
        /* webpackChunkName: "user" */ "@/views/taxRiskControl/riskAnalyse/RiskReport"
      ),
  },
  {
    path: "/taxRiskControl/riskAnalyse/pdf",
    name: "riskPdf",
    meta: { title: "检测报告pdf" },
    component: () =>
      import(
        /* webpackChunkName: "user" */ "@/views/taxRiskControl/riskAnalyse/RiskPdf"
      ),
  },
  {
    path: "*",
    redirect: "/404",
    hidden: true,
  },
];

export const adminMap = [
  {
    path: "/",
    name: "dashboard",
    component: BasicLayout,
    redirect: "/dashboard",
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        meta: { title: "首页", icon: "home" },
        component: () => import("@/views/Dashboard"),
      },
      {
        path: "/userManage",
        name: "userManage",
        meta: { title: "人员管理", icon: "team" },
        component: RouteView,
        redirect: "/userManage/user",
        children: [
          {
            path: "/userManage/user",
            name: "user",
            meta: { title: "用户管理" },
            component: () => import("@/views/userManage/user/Index"),
          },
          // {
          //   path: "/userManage/position",
          //   name: "position",
          //   meta: { title: "岗位管理" },
          //   component: () => import("@/views/userManage/position/Index"),
          // },
          // {
          //   path: "/userManage/role",
          //   name: "role",
          //   meta: { title: "角色管理" },
          //   component: () => import("@/views/userManage/role/Index"),
          // },
        ],
      },
      {
        path: "/tenantManagement",
        name: "tenantManagement",
        meta: { title: "租户信息管理", icon: "database" },
        component: RouteView,
        redirect: "/tenantManagement/tenant",
        children: [
          {
            path: "/tenantManagement/tenant",
            name: "tenant",
            meta: { title: "租户管理" },
            component: () => import("@/views/tenantManagement/tenant/Index"),
          },
          {
            path: "/tenantManagement/fields",
            name: "fields",
            meta: { title: "扩展字段管理" },
            component: () => import("@/views/tenantManagement/fields/Index"),
          },
        ],
      },
      {
        path: "/cataManage",
        name: "cataManage",
        meta: { title: "目录树管理", icon: "apartment" },
        component: () => import("@/views/cataManage/Index"),
      },
      {
        path: "/dataTableManage",
        name: "dataTableManage",
        meta: { title: "数据表管理", icon: "file-text" },
        component: () => import("@/views/dataTableManage/Index"),
      },
      {
        path: "/riskAnalyseRule",
        name: "riskAnalyseRule",
        meta: { title: "风险分析指标管理", icon: "bar-chart" },
        component: () => import("@/views/riskAnalyseRule/Index"),
      },
      {
        path: "/combRiskIndex",
        name: "combRiskIndex",
        meta: { title: "组合风险指标管理", icon: "deployment-unit" },
        component: () => import("@/views/combRiskIndex/Index"),
      },
      {
        path: "/templateManage",
        name: "templateManage",
        meta: { title: "Excel模板管理", icon: "file-excel" },
        component: () => import("@/views/templateManage/Index"),
      },
      // {
      //   path: "/appManage",
      //   name: "appManage",
      //   meta: { title: "应用配置管理", icon: "appstore" },
      //   component: RouteView,
      //   redirect: "/appManage/app",
      //   children: [
      //     {
      //       path: "/appManage/app",
      //       name: "app",
      //       meta: { title: "应用管理" },
      //       component: () => import("@/views/appManage/app/Index"),
      //     },
      //     {
      //       path: "/appManage/tab",
      //       name: "tab",
      //       meta: { title: "选项卡管理" },
      //       component: () => import("@/views/appManage/tab/Index"),
      //     },
      //     {
      //       path: "/appManage/object",
      //       name: "object",
      //       meta: { title: "对象管理" },
      //       component: RouteView,
      //       redirect: "/appManage/object/list",
      //       children: [
      //         {
      //           path: "/appManage/object/list",
      //           meta: { title: "对象管理" },
      //           component: () => import("@/views/appManage/object/Index"),
      //         },
      //         {
      //           path: "/appManage/object/detail",
      //           meta: { title: "对象管理" },
      //           component: () => import("@/views/appManage/object/Detail"),
      //         },
      //       ],
      //     },
      //   ],
      // },
      // {
      //   path: "/ruleManage",
      //   name: "ruleManage",
      //   meta: { title: "规则管理", icon: "exception" },
      //   component: RouteView,
      //   redirect: "/ruleManage/trigger",
      //   children: [
      //     {
      //       path: "/ruleManage/trigger",
      //       name: "trigger",
      //       meta: { title: "触发器管理" },
      //       component: () => import("@/views/ruleManage/trigger/Index"),
      //     },
      //     {
      //       path: "/ruleManage/sla",
      //       name: "sla",
      //       meta: { title: "SLA管理" },
      //       component: () => import("@/views/ruleManage/sla/Index"),
      //     },
      //     {
      //       path: "/ruleManage/sign",
      //       name: "sign",
      //       meta: { title: "签到管理" },
      //       component: () => import("@/views/ruleManage/sign/Index"),
      //     },
      //     {
      //       path: "/ruleManage/worktime",
      //       name: "worktime",
      //       meta: { title: "工作时间管理" },
      //       component: () => import("@/views/ruleManage/worktime/Index"),
      //     },
      //     {
      //       path: "/ruleManage/vacation",
      //       name: "vacation",
      //       meta: { title: "节假日管理" },
      //       component: () => import("@/views/ruleManage/vacation/Index"),
      //     },
      //   ],
      // },
      // {
      //   path: "/sysManage",
      //   name: "sysManage",
      //   meta: { title: "系统管理", icon: "setting" },
      //   component: RouteView,
      //   redirect: "/sysManage/message",
      //   children: [
      //     {
      //       path: "/sysManage/message",
      //       name: "message",
      //       meta: { title: "系统消息" },
      //       component: () => import("@/views/sysManage/message/Index"),
      //     },
      //     {
      //       path: "/sysManage/setting",
      //       name: "setting",
      //       meta: { title: "系统设置" },
      //       component: () => import("@/views/sysManage/setting/Index"),
      //     },
      //   ],
      // },
    ],
  },
  {
    path: "*",
    redirect: "/404",
    hidden: true,
  },
];

export const asyncRouterMap = [
  // ...adminMap,
  // ...appMap,
  // {
  //   path: "/app",
  //   name: "app",
  //   component: AppLayout,
  //   redirect: "/app/home",
  //   children: [
  //     {
  //       path: "/app/home",
  //       name: "home",
  //       meta: { title: "主页", icon: "home" },
  //       component: () => import("@/views/app/Home"),
  //     },
  //     {
  //       path: "/app/recent",
  //       name: "recent",
  //       meta: { title: "最近", icon: "appstore" },
  //       component: RouteView,
  //       redirect: "/app/recent/order",
  //       children: [
  //         {
  //           path: "/app/recent/order",
  //           name: "order",
  //           meta: { title: "数据采集" },
  //           component: () => import("@/views/app/DataCollect"),
  //         },
  //         {
  //           path: "/app/recent/order1",
  //           name: "order1",
  //           meta: { title: "风险检测" },
  //           component: () => import("@/views/app/riskAnalyse/RiskInspect"),
  //         },
  //       ],
  //     },
  //     // {
  //     //   path: "/app/order",
  //     //   name: "order",
  //     //   meta: { title: "数据", icon: "file-text" },
  //     //   component: RouteView,
  //     //   redirect: "/app/order/list",
  //     //   hideChildren: true,
  //     //   children: [
  //     //     {
  //     //       path: "/app/order/list",
  //     //       name: "list",
  //     //       meta: { title: "客户需求单" },
  //     //       component: () => import("@/views/app/List"),
  //     //     },
  //     //     {
  //     //       path: "/app/order/detail",
  //     //       name: "detail",
  //     //       meta: { title: "客户需求单" },
  //     //       component: () => import("@/views/app/Detail"),
  //     //     },
  //     //   ],
  //     // },
  //     {
  //       path: "/app/dataCollect",
  //       name: "dataCollect",
  //       meta: { title: "数据采集", icon: "file-text" },
  //       component: () => import("@/views/app/DataCollect"),
  //     },
  //     {
  //       path: "/app/riskAnalyse",
  //       name: "riskAnalyse",
  //       meta: { title: "风险检测", icon: "file-text" },
  //       component: RouteView,
  //       redirect: "/app/riskAnalyse/riskInspect",
  //       children: [
  //         {
  //           path: "/app/riskAnalyse/riskInspect",
  //           name: "riskInspect",
  //           meta: { title: "风险分析" },
  //           component: () => import("@/views/app/riskAnalyse/RiskInspect"),
  //         },
  //         {
  //           path: "/app/riskAnalyse/history",
  //           name: "history",
  //           meta: { title: "检测记录" },
  //           component: () =>
  //             import("@/views/app/riskAnalyse/RiskInspectHistory"),
  //         },
  //       ],
  //     },
  //     {
  //       path: "/app/reportAnalyse",
  //       name: "reportAnalyse",
  //       meta: { title: "申报检测", icon: "file-text" },
  //       component: RouteView,
  //       redirect: "/app/reportAnalyse/reportCompare",
  //       children: [
  //         {
  //           path: "/app/reportAnalyse/reportCompare",
  //           name: "reportCompare",
  //           meta: { title: "申报自查" },
  //           component: () => import("@/views/app/reportAnalyse/ReportCompare"),
  //         },
  //         {
  //           path: "/app/reportAnalyse/history",
  //           name: "history",
  //           meta: { title: "自查记录" },
  //           component: () =>
  //             import("@/views/app/reportAnalyse/ReportCompareHistory"),
  //         },
  //       ],
  //     },
  //   ],
  // },
];

export const constantRouterMap = [
  {
    path: "/user",
    component: UserLayout,
    redirect: "/user/login",
    hidden: true,
    children: [
      {
        path: "login",
        name: "login",
        meta: { title: "中移税务风控" },
        component: () =>
          import(/* webpackChunkName: "user" */ "@/views/user/Login"),
      },
      // {
      //   path: "register",
      //   name: "register",
      //   component: () =>
      //     import(/* webpackChunkName: "user" */ "@/views/user/Register"),
      // },
    ],
  },
  {
    path: "/404",
    component: () =>
      import(/* webpackChunkName: "fail" */ "@/views/exception/404"),
  },
];
