var render = function render(){var _vm=this,_c=_vm._self._c;return (
    [
      'input',
      'textarea',
      'date',
      'time',
      'number',
      'radio',
      'checkbox',
      'select',
      'rate',
      'switch',
      'slider',
      'uploadImg',
      'uploadFile',
      'cascader',
      'treeSelect',
    ].includes(_vm.record.type)
  )?_c('a-form-model-item',{attrs:{"prop":`domains.${_vm.index}.${_vm.record.model}`,"rules":_vm.record.rules}},[(_vm.record.type === 'textarea')?_c('a-textarea',{style:(`width:${_vm.record.options.width}`),attrs:{"autoSize":{
      minRows: _vm.record.options.minRows,
      maxRows: _vm.record.options.maxRows,
    },"disabled":_vm.record.options.disabled || _vm.parentDisabled,"placeholder":_vm.record.options.placeholder,"allowClear":_vm.record.options.clearable,"maxLength":_vm.record.options.maxLength,"rows":4,"value":_vm.value},on:{"change":function($event){return _vm.handleChange($event.target.value)}}}):(_vm.record.type === 'radio')?_c('a-radio-group',{attrs:{"options":!_vm.record.options.dynamic
        ? _vm.record.options.options
        : _vm.dynamicData[_vm.record.options.dynamicKey]
        ? _vm.dynamicData[_vm.record.options.dynamicKey]
        : [],"disabled":_vm.record.options.disabled || _vm.parentDisabled,"placeholder":_vm.record.options.placeholder,"value":_vm.value,"checked":_vm.value},on:{"change":function($event){return _vm.handleChange($event.target.value)}}}):(_vm.record.type === 'checkbox')?_c('a-checkbox-group',{attrs:{"options":!_vm.record.options.dynamic
        ? _vm.record.options.options
        : _vm.dynamicData[_vm.record.options.dynamicKey]
        ? _vm.dynamicData[_vm.record.options.dynamicKey]
        : [],"disabled":_vm.record.options.disabled || _vm.parentDisabled,"placeholder":_vm.record.options.placeholder,"value":_vm.value},on:{"change":_vm.handleChange}}):(_vm.record.type === 'slider')?_c('div',{staticClass:"slider-box",style:(`width:${_vm.record.options.width}`)},[_c('div',{staticClass:"slider"},[_c('a-slider',{attrs:{"disabled":_vm.record.options.disabled || _vm.parentDisabled,"min":_vm.record.options.min,"max":_vm.record.options.max,"step":_vm.record.options.step,"value":_vm.value},on:{"change":_vm.handleChange}})],1),(_vm.record.options.showInput)?_c('div',{staticClass:"number"},[_c('a-input-number',{staticStyle:{"width":"100%"},attrs:{"disabled":_vm.record.options.disabled || _vm.parentDisabled,"min":_vm.record.options.min,"max":_vm.record.options.max,"step":_vm.record.options.step,"value":_vm.value},on:{"change":_vm.handleChange}})],1):_vm._e()]):_c(_vm.componentItem,_vm._b({tag:"component",style:(`width:${_vm.record.options.width}`),attrs:{"min":_vm.record.options.min || _vm.record.options.min === 0
        ? _vm.record.options.min
        : -Infinity,"max":_vm.record.options.max || _vm.record.options.max === 0
        ? _vm.record.options.max
        : Infinity,"count":_vm.record.options.max,"precision":_vm.record.options.precision > 50 ||
      (!_vm.record.options.precision && _vm.record.options.precision !== 0)
        ? null
        : _vm.record.options.precision,"record":_vm.record,"config":_vm.config,"disabled":_vm.record.options.disabled || _vm.parentDisabled,"parentDisabled":_vm.record.options.disabled || _vm.parentDisabled,"allowClear":_vm.record.options.clearable,"dynamicData":_vm.dynamicData,"filterOption":_vm.record.options.showSearch
        ? (inputValue, option) => {
            return (
              option.componentOptions.children[0].text
                .toLowerCase()
                .indexOf(inputValue.toLowerCase()) >= 0
            );
          }
        : false,"treeData":!_vm.record.options.dynamic
        ? _vm.record.options.options
        : _vm.dynamicData[_vm.record.options.dynamicKey]
        ? _vm.dynamicData[_vm.record.options.dynamicKey]
        : [],"options":!_vm.record.options.dynamic
        ? _vm.record.options.options
        : _vm.dynamicData[_vm.record.options.dynamicKey]
        ? _vm.dynamicData[_vm.record.options.dynamicKey]
        : [],"mode":_vm.record.options.multiple ? 'multiple' : '',"checked":_vm.value,"value":_vm.value},on:{"change":function($event){return _vm.handleChange($event)}}},'component',_vm.componentOption,false))],1):(_vm.record.type === 'text')?_c('a-form-model-item',[_c('div',{style:({ textAlign: _vm.record.options.textAlign })},[_c('label',{class:{ 'ant-form-item-required': _vm.record.options.showRequiredMark },style:({
        fontFamily: _vm.record.options.fontFamily,
        fontSize: _vm.record.options.fontSize,
        color: _vm.record.options.color,
      }),domProps:{"textContent":_vm._s(_vm.record.label)}})])]):(_vm.record.type === 'html')?_c('div',{domProps:{"innerHTML":_vm._s(_vm.record.options.defaultValue)}}):_c('div')
}
var staticRenderFns = []

export { render, staticRenderFns }