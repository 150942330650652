<!--
 * @Author: zhangjingqing
 * @Date: 2022-02-10 22:54:32
 * @LastEditors: zhangjinqing
 * @LastEditTime: 2022-06-11 20:18:41
 * @Description: file content
 * @FilePath: \lcdp-front-web\src\components\GlobalHeader.vue
-->
<template>
  <a-layout-header :style="{ padding: '0' }">
    <div class="header">
      <a-dropdown v-if="tenantInfo">
        <span class="trigger"
          ><a-icon :type="'appstore'"> </a-icon> 我的应用</span
        >
        <div class="applist" slot="overlay">
          <div v-for="(item, index) in apps" class="app" :key="index">
            <router-link :to="item.route">
              <img :src="item.url" />
              <h2>{{ item.name }}</h2>
            </router-link>
          </div>
        </div>
      </a-dropdown>
      <user-menu></user-menu>
    </div>
  </a-layout-header>
</template>

<script>
import { mapGetters } from "vuex";
import UserMenu from "@/components/UserMenu";
export default {
  name: "GlobalHeader",
  components: { UserMenu },
  data() {
    return {
      apps: [
        {
          name: "中移税务风控",
          url: require("@/assets/img/icon_gzt@2x.png"),
          route: "/taxRiskControl",
        },
        // {
        //   name: "应用",
        //   url: require("@/assets/img/icon_glht@2x.png"),
        //   route: "/app",
        // },
        // {
        //   name: "钱包",
        //   url: require("@/assets/img/icon_gzb@2x.png"),
        //   route: "/app",
        // },
        // {
        //   name: "人员",
        //   url: require("@/assets/img/icon_znrs@2x.png"),
        //   route: "/app",
        // },
      ],
    };
  },
  computed: {
    ...mapGetters(["tenantInfo"]),
  },
};
</script>
<style lang="less" scoped>
.applist {
  display: flex;
  flex-wrap: wrap;
  max-width: 340px;
  padding: 10px 20px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

  .app {
    width: 100px;
    text-align: center;
    padding: 10px 0;
    cursor: pointer;
    img {
      width: 48px;
      height: 48px;
    }
    h2 {
      margin-top: 10px;
      font-size: 12px;
    }
    &:hover {
      h2 {
        color: @primary-color;
      }
    }
  }
}
</style>
