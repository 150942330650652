/*
 * @Author: zhangjingqing
 * @Date: 2022-02-10 22:54:32
 * @LastEditors: zhangjinqing
 * @LastEditTime: 2022-05-04 19:53:34
 * @Description: file content
 * @FilePath: \lcdp-front-web\src\store\index.js
 */
import Vue from "vue";
import Vuex from "vuex";
import getters from "./getters";

import user from "./modules/user";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
  },
  state: {},
  mutations: {},
  actions: {},
  getters,
});
