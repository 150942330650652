<!--
 * @Author: zhangjingqing
 * @Date: 2022-03-03 23:20:42
 * @LastEditors: zhangjinqing
 * @LastEditTime: 2022-05-12 11:25:47
 * @Description: file content
 * @FilePath: \lcdp-front-web\src\components\GlobalTab.vue
-->
<template>
  <div class="container">
    <transition name="fade">
      <a-tabs v-model="activePage" hide-add type="editable-card" @edit="onEdit">
        <a-tab-pane
          v-for="page in pageList"
          :id="page.fullPath"
          :key="page.fullPath"
          :closable="true"
        >
          <span slot="tab" :pagekey="page.fullPath">{{ page.meta.title }}</span>
        </a-tab-pane>
      </a-tabs>
    </transition>
  </div>
</template>
<script>
export default {
  name: "",
  data() {
    return {
      activePage: "",
      pageList: [],
      linkList: [],
    };
  },
  created() {
    let currentRoute = Object.assign({}, this.$route);
    currentRoute.meta = Object.assign({}, currentRoute.meta);
    this.pageList.push(currentRoute);
    this.linkList.push(currentRoute.fullPath);
    this.activePage = currentRoute.fullPath;
  },
  methods: {
    onEdit(targetKey) {
      if (targetKey == "/dashboard") {
        return;
      }
      let index = this.pageList.indexOf(
        this.pageList.filter((item) => item.fullPath == targetKey)[0]
      );
      this.pageList.splice(index, 1);
      let index1 = this.linkList.indexOf(targetKey);
      this.linkList.splice(index1, 1);
      if (this.linkList.length == 0) {
        this.$router.push("/");
        return;
      }
      let i = index1 - 1;
      this.activePage = this.linkList[i == -1 ? 0 : i];
    },
  },
  watch: {
    $route: function (newRoute) {
      this.activePage = newRoute.fullPath;
      if (this.linkList.indexOf(newRoute.fullPath) < 0) {
        this.linkList.push(newRoute.fullPath);
        this.pageList.push(Object.assign({}, newRoute));
      }
    },
    activePage: function (key) {
      let index = this.linkList.lastIndexOf(key);
      let waitRouter = this.pageList[index];
      // 【TESTA-523】修复：不允许重复跳转路由异常
      if (waitRouter.fullPath !== this.$route.fullPath) {
        this.$router.push(Object.assign({}, waitRouter));
      }
    },
  },
};
</script>
<style lang="less" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

/deep/ .ant-tabs-bar {
  margin: 0;
}
.container {
  padding: 20px 20px 0;
  background: #fff;
}
</style>
