import axios from "axios";
import Vue from "vue";
import store from "@/store";
// import * as devConfig from "../../../config/myConfig";
import { ACCESS_TOKEN } from "../../store/mutation-types";
import { Modal, notification, message } from "ant-design-vue";
import { getQueryString } from "@/utils/utils";

const CONSOLE_COLOR = "background: #4368aa;color: #FFFFFF";
const CONSOLE_COLOR_ERROR = "background: #e83838;color: #FFFFFF";
// const ENABLE_DEBUG =
//   devConfig.ENABLE_API_LOG === undefined
//     ? process.env.NODE_ENV !== "production"
//     : devConfig.ENABLE_API_LOG;
const ENABLE_DEBUG = false;

// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL || "/api", // api的base_url
  timeout: 1 * 60 * 1000,
});

service.interceptors.response.use(
  (response) => {
    printResponse(response);
    if (response.config.method === "options") return; // 如果是预检请求直接返回

    let data = response.data;
    if (!data.success && data.code == 500) {
      handleNetworkError(data);
    }
    if (response.data instanceof Blob) {
      return response;
    }
    return data;
  },
  (error) => {
    if (error.response) {
      let data = error.response.data;
      const token = Vue.ls.get(ACCESS_TOKEN);
      console.log("------异常响应------", token);
      console.log("------异常响应------", error.response.status);
      switch (error.response.status) {
        case 403:
          notification.error({
            message: "系统提示",
            description: "拒绝访问",
            duration: 4,
          });
          break;
        case 500: {
          let type = error.response.request.responseType;
          if (type === "blob") {
            blobToJson(data);
            break;
          }
          let msg = data.msg || data.message;
          let len = msg.length;
          notification.error({
            message: "系统提示",
            description: len > 100 ? "系统错误，请联系管理员" : msg,
            duration: 4,
          });
          break;
        }
        case 404:
          notification.error({
            message: "系统提示",
            description: "很抱歉，资源未找到!",
            duration: 4,
          });
          break;
        case 504:
          notification.error({ message: "系统提示", description: "网络超时" });
          break;
        case 400:
          notification.error({
            message: "系统提示",
            description: data.msg || data.message,
            duration: 4,
          });
          if (
            data.msg == "用户登录信息已过期，请重新登录" ||
            data.msg == "根据token获取用户登录信息不存在，请重新登录"
          ) {
            if (token) {
              store.dispatch("Logout").finally(() => {
                setTimeout(() => {
                  window.location.reload();
                }, 1500);
              });
            }
          }
          break;
        case 401:
          notification.error({
            message: "系统提示",
            description: "未授权，请重新登录",
            duration: 4,
          });
          if (token) {
            store.dispatch("Logout").finally(() => {
              setTimeout(() => {
                window.location.reload();
              }, 1500);
            });
          }
          break;
        default:
          notification.error({
            message: "系统提示",
            description: data.message,
            duration: 4,
          });
          break;
      }
    }
    return Promise.reject(error);
  }
);

service.interceptors.request.use((config) => {
  let token = getQueryString("token") || Vue.ls.get(ACCESS_TOKEN);
  if (token) {
    config.headers["token"] = token;
  }
  return config;
});

/**
 * Blob解析
 * @param data
 */
function blobToJson(data) {
  let fileReader = new FileReader();
  let token = Vue.ls.get(ACCESS_TOKEN);
  fileReader.onload = function () {
    try {
      let jsonData = JSON.parse(this.result); // 说明是普通对象数据，后台转换失败
      if (jsonData.status === 500) {
        if (token && jsonData.message.includes("Token失效")) {
          Modal.error({
            title: "登录已过期",
            content: "很抱歉，登录已过期，请重新登录",
            okText: "重新登录",
            mask: false,
            onOk: () => {
              store.dispatch("user/Logout").then(() => {
                Vue.ls.remove(ACCESS_TOKEN);
                window.location.reload();
              });
            },
          });
        }
      }
    } catch (err) {
      // 解析成对象失败，说明是正常的文件流
      console.log("blob解析fileReader返回err", err);
    }
  };
  fileReader.readAsText(data);
}

function printResponse(response) {
  if (ENABLE_DEBUG) {
    let hasError = response.data.error;
    if (hasError) {
      console.log(
        "%c Http ",
        CONSOLE_COLOR_ERROR,
        "<- URL: " + response.config.url + "\n       <- error:",
        response.data.error
      );
    } else {
      console.log(
        "%c Http ",
        CONSOLE_COLOR,
        "<- URL: " + response.config.url + "\n       <- data:",
        response.data
      );
    }
  }
}

function handleNetworkError(error) {
  message.error({
    content: error?.message || "网络异常！请稍后再试！",
  });
}

export default service;
