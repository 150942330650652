<!--
 * @Author: zhangjingqing
 * @Date: 2022-02-11 13:46:33
 * @LastEditors: zhangjinqing
 * @LastEditTime: 2022-02-24 09:13:24
 * @Description: file content
 * @FilePath: \lcdp-front-web\src\components\AppSideMenu.vue
-->
<template>
  <a-layout-sider v-model="collapsed" collapsible>
    <div class="logo" />
    <div class="searchBar">
      <a-input-search placeholder="请输入名称" @search="onSearch" />
    </div>
    <a-menu
      theme="dark"
      mode="inline"
      :defaultSelectedKeys="[$route.path]"
      :openKeys="openKeys"
      @openChange="onOpenChange"
      @click="menuClick"
    >
      <template v-for="menu in menus">
        <template
          v-if="menu.children && menu.children.length > 0 && !menu.hideChildren"
        >
          <a-sub-menu :key="menu.path">
            <span slot="title">
              <a-icon :type="menu.meta.icon" />
              <span>{{ menu.meta.title }}</span>
            </span>
            <a-menu-item :key="subMenu.path" v-for="subMenu in menu.children">
              {{ subMenu.meta.title }}
            </a-menu-item>
          </a-sub-menu>
        </template>
        <template v-else>
          <a-menu-item :key="menu.path">
            <a-icon :type="menu.meta.icon" />
            <span>{{ menu.meta.title }}</span>
          </a-menu-item>
        </template>
      </template>
    </a-menu>
  </a-layout-sider>
</template>

<script>
import { adminMap } from "@/router/router.config";
export default {
  name: "AppSideMenu",
  data() {
    return {
      collapsed: false,
      menus: adminMap[1].children,
      selectedKeys: [],
      openKeys: [""],
    };
  },
  mounted() {
    this.selectMenu();
  },
  methods: {
    menuClick(menu) {
      this.$router.push({ path: menu.key });
    },
    onSearch() {},
    selectMenu() {
      let menus = this.menus;
      for (let i = 0; i < menus.length; i++) {
        if (menus[i].children) {
          for (let y = 0; y < menus[i].children.length; y++) {
            if (this.$route.path.indexOf(menus[i].children[y].path) != -1) {
              this.openKeys = [menus[i].path];
            }
          }
        }
      }
      let path;
      let len = this.$route.path.split("/").length;
      if (len > 3) {
        path = this.$route.path.substring(0, this.$route.path.lastIndexOf("/"));
      } else {
        path = this.$route.path;
      }
      this.selectedKeys.push(path);
    },
    onOpenChange(openKeys) {
      if (openKeys.length !== 0) {
        this.openKeys = [openKeys[1]];
      } else {
        this.openKeys = [""];
      }
    },
  },
};
</script>

<style scoped>
.logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}
.searchBar {
  padding: 10px 20px;
}
</style>
