/*
 * @Author: zhangjingqing
 * @Date: 2022-04-09 11:03:12
 * @LastEditors: zhangjinqing
 * @LastEditTime: 2022-06-03 22:55:34
 * @Description: file content
 * @FilePath: \lcdp-front-web\src\api\tenant.js
 */
import { getAction, postAction } from "./http/manage";
const URL = process.env.VUE_APP_API_PREFIX;

/* 
  租户相关
*/

// 根据id查询租户信息
const querySystemTenantid = (params) =>
  postAction(URL + "/tenantid/querySystemTenantid?id=" + params.id);
// 查询租户列表
const querySystemTenantidList = (params) =>
  getAction(URL + "/tenantid/querySystemTenantidList", params);
// 创建租户
const createSystemTenantid = (params) =>
  postAction(URL + "/tenantid/createSystemTenantid", params);
// 删除租户
const deleteSystemTenantid = (params) =>
  postAction(URL + "/tenantid/deleteSystemTenantid?id=" + params.id);
// 编辑租户
const updateSystemTenantid = (params) =>
  postAction(URL + "/tenantid/updateSystemTenantid", params);

/*
  租户关联菜单
*/

// 关联租户和菜单
const createSystemTenantidMenu = (params) =>
  postAction(URL + "/tenantidMenu/createSystemTenantidMenu", params);
// 删除租户的菜单
const deleteSystemTenantidMenu = (params) =>
  postAction(URL + "/tenantidMenu/deleteSystemTenantidMenu", params);
// 根据扩展字段编码查询关联租户和菜单
const querySystemTenantidMenu = (params) =>
  postAction(URL + "/tenantidMenu/querySystemTenantidMenu", params);
// 查询租户关联菜单列表
const querySystemTenantidMenuList = (params) =>
  getAction(URL + "/tenantidMenu/querySystemTenantidMenuList", params);

/*
  租户配置
*/

// 创建扩展字段
const createExtendField = (params) =>
  postAction(URL + "/extendField/createExtendField", params);
// 查询扩展字段列表，分页
const queryExtendFieldServiceList = (params) =>
  getAction(URL + "/extendField/queryExtendFieldServiceList", params);
// 编辑扩展字段
const updateExtendField = (params) =>
  postAction(URL + "/extendField/updateExtendField", params);
// 删除扩展字段
const deleteExtendField = (params) =>
  postAction(URL + "/extendField/deleteExtendField?id=" + params.id);

/*
  扩展字段配置
*/

// 创建租户配置
const createSystemTenantidCfg = (params) =>
  postAction(URL + "/tenantidCfg/createSystemTenantidCfg", params);
// 根据id查询租户配置信息
const querySystemTenantidCfg = (params) =>
  postAction(URL + "/tenantidCfg/querySystemTenantidCfg?tenantid=" + params.id);
// 编辑租户配置信息
const updateSystemTenantidCfg = (params) =>
  postAction(URL + "/tenantidCfg/updateSystemTenantidCfg", params);

/*
  租户关联扩展字段
*/

// 关联租户和扩展字段
const createSystemTenantidExtend = (params) =>
  postAction(URL + "/tenantidExtend/createSystemTenantidExtend", params);
// 删除租户的扩展字段
const deleteSystemTenantidExtend = (params) =>
  postAction(
    URL +
      "/tenantidExtend/deleteSystemTenantidExtend?tenantId=" +
      params.tenantId +
      "&expandFieldId=" +
      params.expandFieldId
  );
// 根据扩展字段编码查询关联租户和扩展字段信息
const querySystemTenantidExtend = (params) =>
  postAction(
    URL + "/tenantidExtend/querySystemTenantidExtend?tenantid=" + params.id
  );
// 查询租户扩展字段列表
const querySystemTenantidExtendList = (params) =>
  getAction(URL + "/tenantidExtend/querySystemTenantidExtendList", params);
// 编辑租户的扩展字段
const updateSystemTenantidExtend = (params) =>
  postAction(URL + "/tenantidExtend/updateSystemTenantidExtend", params);
// 关联租户和扩展字段（批量）
const createSystemTenantidExtendBatch = (params) =>
  postAction(URL + "/tenantidExtend/createSystemTenantidExtendBatch", params);

export {
  querySystemTenantid,
  querySystemTenantidList,
  createSystemTenantid,
  deleteSystemTenantid,
  updateSystemTenantid,
  createSystemTenantidMenu,
  deleteSystemTenantidMenu,
  querySystemTenantidMenu,
  querySystemTenantidMenuList,
  createSystemTenantidCfg,
  querySystemTenantidCfg,
  updateSystemTenantidCfg,
  createExtendField,
  queryExtendFieldServiceList,
  updateExtendField,
  deleteExtendField,
  createSystemTenantidExtend,
  deleteSystemTenantidExtend,
  querySystemTenantidExtend,
  querySystemTenantidExtendList,
  updateSystemTenantidExtend,
  createSystemTenantidExtendBatch,
};
