/*
 * @Author: zhangjingqing
 * @Date: 2022-04-26 22:38:55
 * @LastEditors: zhangjinqing
 * @LastEditTime: 2022-06-22 21:08:23
 * @Description: file content
 * @FilePath: \lcdp-front-web\src\utils\htmlToPdf.js
 */
import html2Canvas from "html2canvas";
import JsPDF from "jspdf";

export default {
  install(Vue) {
    Vue.prototype.exportPdfHandler = function (el, title, imgtype) {
      // el 要导出元素的id
      // title 文件名称
      // imgtype 图片类型 默认png
      let imgType = imgtype ? imgtype : "jpeg";
      let element = document.getElementById(el);
      html2Canvas(element, {
        allowTaint: true,
      }).then(function (canvas) {
        let contentWidth = canvas.width;
        let contentHeight = canvas.height;
        let pageHeight = (contentWidth / 592.28) * 841.89;
        let leftHeight = contentHeight;
        let position = 0;
        let imgWidth = 595.28;
        let imgHeight = (592.28 / contentWidth) * contentHeight;
        let pageData = canvas.toDataURL("image/" + imgType.toLowerCase(), 1.0);
        let PDF = new JsPDF("", "pt", "a4");
        if (leftHeight < pageHeight) {
          PDF.addImage(
            pageData,
            imgType.toUpperCase(),
            0,
            0,
            imgWidth,
            imgHeight
          );
        } else {
          while (leftHeight > 0) {
            PDF.addImage(
              pageData,
              imgType.toUpperCase(),
              0,
              position,
              imgWidth,
              imgHeight
            );
            leftHeight -= pageHeight;
            position -= 841.89;
            if (leftHeight > 0) {
              PDF.addPage();
            }
          }
        }
        PDF.save(title + ".pdf");
      });
    };
  },
};
