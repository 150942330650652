<!--
 * @Author: zhangjingqing
 * @Date: 2022-02-10 22:54:32
 * @LastEditors: zhangjinqing
 * @LastEditTime: 2022-03-03 23:36:10
 * @Description: file content
 * @FilePath: \lcdp-front-web\src\layouts\BasicLayout.vue
-->
<template>
  <a-layout class="layout">
    <global-side-menu></global-side-menu>
    <a-layout style="height: 100%">
      <global-header />
      <a-layout-content>
        <global-tab></global-tab>
        <div class="main">
          <router-view />
        </div>
        <a-layout-footer style="padding: 0px">
          <global-footer />
        </a-layout-footer>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>

<script>
import GlobalHeader from "../components/GlobalHeader";
import GlobalFooter from "../components/GlobalFooter";
import GlobalSideMenu from "../components/GlobalSideMenu";
import GlobalTab from "../components/GlobalTab";
export default {
  name: "BasicLayout",
  components: {
    GlobalHeader,
    GlobalFooter,
    GlobalSideMenu,
    GlobalTab,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="less">
@height: 64px;
.layout {
  height: 100%;
  .header {
    z-index: 2;
    height: @height;
    transition: background 300ms;
    padding: 0 12px 0 0;
    background: #fff;
    box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
    position: relative;
    .trigger {
      font-size: 16px;
      height: @height;
      line-height: @height;
      margin-left: 24px;
      padding: 0 0 5px 0;
      cursor: pointer;
      transition: color 0.3s;
      &:hover {
        color: @primary-color;
      }
    }

    .user-wrapper {
      float: right;
      height: 100%;
      .action {
        cursor: pointer;
        padding: 0 14px;
        display: inline-block;
        transition: all 0.3s;

        height: 70%;
        line-height: 46px;

        &.action-full {
          height: 100%;
          line-height: 64px;
        }

        &:hover {
          background: rgba(255, 255, 255, 0.3);
        }

        .avatar {
          margin: 18px 10px 22px 0;
          color: #1890ff;
          background: hsla(0, 0%, 100%, 0.85);
          vertical-align: middle;
        }

        .icon {
          font-size: 16px;
          padding: 4px;
        }

        .anticon {
          color: inherit;
        }
      }
    }
  }
  .ant-layout-content {
    max-height: calc(100% - 64px);
    overflow-x: hidden;
    overflow-y: auto;
    .main {
      margin: 20px;
    }
  }
}
</style>
