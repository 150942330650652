/*
 * @Author: zhangjingqing
 * @Date: 2022-02-17 16:37:58
 * @LastEditors: zhangjinqing
 * @LastEditTime: 2022-08-13 22:27:22
 * @Description: file content
 * @FilePath: \lcdp-front-web\src\api\login.js
 */

import { getAction, getActionHeader, postAction } from "./http/manage";
const URL = process.env.VUE_APP_API_PREFIX;
// 邮箱是否存在
const emailIsExist = (params) =>
  getAction(URL + "/passport/emailIsExist", params);
// 登录
const login = (params) => postAction(URL + "/passport/login", params);
// 获取当前登录用户信息
const queryLoginUsrInfo = (params) =>
  postAction(URL + "/passport/queryLoginUsrInfo", params);
// 注册
const registerSystemTenantid = (params) =>
  postAction(URL + "/tenantid/registerSystemTenantid", params);
// u8注册
const registeru8 = (params) => postAction(URL + "/tenantid/registeru8", params);
// u8c注册
const registeru8c = (params) =>
  postAction(URL + "/tenantid/registeru8c", params);
// 验证激活码
const validateCdk = (cdk, params) =>
  getActionHeader(
    "https://testeop.yyu8c.com/api/shuiwu/orderlic/y2a0/" + cdk,
    params
  );

export {
  emailIsExist,
  login,
  queryLoginUsrInfo,
  registerSystemTenantid,
  registeru8,
  registeru8c,
  validateCdk,
};
