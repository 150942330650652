<!--
 * @Author: zhangjingqing
 * @Date: 2022-02-10 22:54:32
 * @LastEditors: zhangjinqing
 * @LastEditTime: 2022-05-06 16:01:04
 * @Description: file content
 * @FilePath: \lcdp-front-web\src\components\GlobalFooter.vue
-->
<template>
  <div class="footer">
    <div class="copyright">
      Copyright
      <a-icon type="copyright" />
      2022 <span>中移税务风控 出品</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "GlobalFooter",
};
</script>

<style lang="less" scoped>
.footer {
  padding: 0 16px;
  margin: 48px 0 24px;
  text-align: center;

  .links {
    margin-bottom: 8px;

    a {
      color: rgba(0, 0, 0, 0.45);

      &:hover {
        color: rgba(0, 0, 0, 0.65);
      }

      &:not(:last-child) {
        margin-right: 40px;
      }
    }
  }
  .copyright {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
  }
}
</style>
