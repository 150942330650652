/*
 * @Author: zhangjingqing
 * @Date: 2022-02-22 21:34:44
 * @LastEditors: zhangjinqing
 * @LastEditTime: 2022-06-19 18:34:28
 * @Description: file content
 * @FilePath: \lcdp-front-web\src\store\getters.js
 */
import Vue from "vue";
const getters = {
  userInfo: (state) => {
    state.user.userInfo = Vue.ls.get("USER_INFO");
    return state.user.userInfo;
  },
  tenantInfo: (state) => {
    state.user.tenantInfo = Vue.ls.get("TENANT_INFO");
    return state.user.tenantInfo;
  },
  fieldInfo: (state) => {
    state.user.fieldInfo = Vue.ls.get("FIELD_INFO");
    return state.user.fieldInfo;
  },
};

export default getters;
