<!--
 * @Author: zhangjingqing
 * @Date: 2022-02-11 11:08:25
 * @LastEditors: zhangjinqing
 * @LastEditTime: 2022-02-17 15:37:24
 * @Description: file content
 * @FilePath: \LowCodeWeb\src\layouts\AppLayout.vue
-->
<template>
  <a-layout class="layout">
    <app-side-menu></app-side-menu>
    <a-layout style="height: 100%">
      <global-header />
      <a-layout-content>
        <div class="main">
          <router-view />
        </div>
        <a-layout-footer style="padding: 0px">
          <global-footer />
        </a-layout-footer>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>

<script>
import GlobalHeader from "../components/GlobalHeader";
import GlobalFooter from "../components/GlobalFooter";
import AppSideMenu from "../components/AppSideMenu";
export default {
  name: "AppLayout",
  components: {
    GlobalHeader,
    GlobalFooter,
    AppSideMenu,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
