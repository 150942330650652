/*
 * @Author: zhangjingqing
 * @Date: 2022-02-10 22:54:32
 * @LastEditors: zhangjinqing
 * @LastEditTime: 2022-06-24 20:38:45
 * @Description: file content
 * @FilePath: \lcdp-front-web\src\router\index.js
 */
import Vue from "vue";
import Router from "vue-router";
import store from "@/store";
import { constantRouterMap, appMap, adminMap } from "./router.config";
import { getQueryString } from "@/utils/utils";

Vue.use(Router);
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRouterMap,
});

export function initDynamicRoutes() {
  if (store.getters.userInfo) {
    let isAdmin = store.getters.userInfo.tenantId === null;
    if (isAdmin) {
      router.addRoutes(adminMap);
    } else {
      router.addRoutes(appMap);
    }
  }
  if (getQueryString("token")) {
    router.addRoutes(appMap);
  }
}

export default router;
