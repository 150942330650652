/*
 * @Author: zhangjingqing
 * @Date: 2022-02-10 22:54:32
 * @LastEditors: zhangjinqing
 * @LastEditTime: 2022-06-19 18:26:19
 * @Description: file content
 * @FilePath: \lcdp-front-web\src\main.js
 */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Storage from "vue-ls";
import config from "@/defaultSettings";
import "@/commonLazy/commonLazy";
import "@/assets/reset.less";
import moment from "moment";
import KFormDesign from "@/generator/packages/index";
// import FormDesign from "@/mobileGenerator/packages/index";
import { ACCESS_TOKEN } from "@/store/mutation-types";
// import { notification } from "ant-design-vue";
const whiteList = [
  "/user/login",
  "/user/register",
  "/taxRiskControl/riskAnalyse/pdf",
]; // no redirect whitelist

KFormDesign.setFormDesignConfig({
  title: "测试自定义字段",
  list: [
    {
      type: "demo", // 表单类型
      // label: "自定义组件", // 标题文字
      icon: "icon-gallery",
      options: {
        defaultValue: undefined,
        multiple: false,
        disabled: false,
        width: "100%",
        clearable: true,
        placeholder: "请选择",
        showSearch: false,
      },
      model: "",
      key: "",
      rules: [
        {
          required: false,
          message: "必填项",
        },
      ],
    },
  ],
  uploadFile: "",
  uploadImage: "",
  uploadFileName: "",
  uploadImageName: "",
  uploadFileData: { data: 1545 },
  uploadImageData: { data: 1545 },
  uploadFileHeaders: { data: 1545 },
  uploadImageHeaders: { data: 1545 },
});
Vue.use(KFormDesign);
// FormDesign.setFormDesignConfig({
//   title: "测试自定义字段",
//   list: [
//     {
//       type: "demo", // 表单类型
//       // label: "自定义组件", // 标题文字
//       icon: "icon-gallery",
//       options: {
//         defaultValue: undefined,
//         multiple: false,
//         disabled: false,
//         width: "100%",
//         clearable: true,
//         placeholder: "请选择",
//         showSearch: false,
//       },
//       model: "",
//       key: "",
//       rules: [
//         {
//           required: false,
//           message: "必填项",
//         },
//       ],
//     },
//   ],
//   uploadFile: "",
//   uploadImage: "",
//   uploadFileName: "",
//   uploadImageName: "",
//   uploadFileData: { data: 1545 },
//   uploadImageData: { data: 1545 },
//   uploadFileHeaders: { data: 1545 },
//   uploadImageHeaders: { data: 1545 },
// });
// Vue.use(FormDesign);

import "xe-utils";
import VXETable from "vxe-table";
import "vxe-table/lib/style.css";

Vue.use(VXETable);

Vue.use(Storage, config.storageOptions);
Vue.config.productionTip = false;
Vue.prototype.$moment = moment;

import htmlToPdf from "@/utils/htmlToPdf";
Vue.use(htmlToPdf);

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }

  if (Vue.ls.get(ACCESS_TOKEN)) {
    next();
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next();
    } else {
      next({ path: "/user/login" });
    }
  }
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
