<template>
  <div id="userLayout" :class="['user-layout-wrapper']">
    <div class="container">
      <div class="panel">
        <!-- <div class="left">
          <img :src="require('@/assets/img/login.png')" alt="" />
        </div> -->
        <div class="right">
          <div class="top">
            <div class="header">
              <a href="/">
                <span class="title">中移税务风控</span>
              </a>
            </div>
          </div>

          <route-view></route-view>
        </div>
      </div>
      <div class="footer">
        <div class="copyright">
          Copyright &copy; 2022
          <a href="javascript:;">中移税务风控</a> 出品
        </div>
        <div class="links">
          <a href="http://beian.miit.gov.cn/" target="_blank"
            >京ICP备17063833号</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RouteView from "./RouteView";
// import { mixinDevice } from "@/utils/mixin.js";

export default {
  name: "UserLayout",
  components: { RouteView },
  // mixins: [mixinDevice],
  data() {
    return {};
  },
  mounted() {
    document.body.classList.add("userLayout");
  },
  beforeDestroy() {
    document.body.classList.remove("userLayout");
  },
};
</script>

<style lang="less">
#userLayout.user-layout-wrapper {
  height: 100%;

  &.mobile {
    .container {
      .main {
        max-width: 368px;
        width: 98%;
      }
    }
  }

  .container {
    width: 100%;
    min-height: 100%;
    background: #f0f2f5 url(~@/assets/bachground1.jpg) no-repeat 50%;
    background-size: 100%;
    position: relative;

    a {
      text-decoration: none;
    }

    .panel {
      display: flex;
      justify-content: center;
      position: absolute;
      top: 50%;
      right: 16%;
      margin-top: -306px;
      margin-left: -612px;
      .left {
        width: 724px;
      }
      .right {
        width: 470px;
        padding-top: 60px;
        background: #fff;
        .top {
          text-align: center;

          .header {
            height: 120px;
            line-height: 120px;

            .badge {
              position: absolute;
              display: inline-block;
              line-height: 1;
              vertical-align: middle;
              margin-left: -12px;
              margin-top: -10px;
              opacity: 0.8;
            }

            .logo {
              height: 44px;
              vertical-align: top;
              margin-right: 16px;
              border-style: none;
            }

            .title {
              font-size: 33px;
              color: rgba(0, 0, 0, 0.85);
              font-family: "Chinese Quote", -apple-system, BlinkMacSystemFont,
                "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei",
                "Helvetica Neue", Helvetica, Arial, sans-serif,
                "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
              font-weight: 600;
              position: relative;
              top: 2px;
            }
          }
          .desc {
            font-size: 14px;
            color: rgba(0, 0, 0, 0.45);
            margin-top: 12px;
            margin-bottom: 40px;
          }
        }
      }
    }

    .main {
      min-width: 260px;
      width: 368px;
      margin: 0 auto;
    }

    .footer {
      position: absolute;
      width: 100%;
      bottom: 0;
      padding: 0 16px;
      margin: 48px 0 24px;
      text-align: center;

      .links {
        font-size: 14px;
        a {
          color: rgba(0, 0, 0, 0.45);
          transition: all 0.3s;
          &:not(:last-child) {
            margin-right: 40px;
          }
        }
      }
      .copyright {
        color: rgba(0, 0, 0, 0.45);
        font-size: 14px;
        margin-bottom: 8px;
      }
    }
  }
}
</style>
