<!--
 * @Author: zhangjingqing
 * @Date: 2022-02-10 22:54:32
 * @LastEditors: zhangjinqing
 * @LastEditTime: 2022-06-19 18:26:45
 * @Description: file content
 * @FilePath: \lcdp-front-web\src\App.vue
-->
<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <router-view />
    </div>
  </a-config-provider>
</template>

<script>
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import { initDynamicRoutes } from "@/router";
export default {
  data() {
    return {
      locale: zhCN,
    };
  },
  created() {
    initDynamicRoutes();
  },
};
</script>
<style lang="less">
#app {
  min-width: 1600px;
  height: 100%;
  overflow: hidden;
}
</style>
