<!--
 * @Author: zhangjingqing
 * @Date: 2022-02-11 14:47:55
 * @LastEditors: zhangjinqing
 * @LastEditTime: 2022-06-19 18:59:35
 * @Description: file content
 * @FilePath: \lcdp-front-web\src\components\UserMenu.vue
-->
<template>
  <div class="user-wrapper">
    <router-link to="/" class="logout_title action">
      <a-icon type="setting"></a-icon>
    </router-link>
    <header-notice class="action" />
    <a-dropdown>
      <span class="action action-full ant-dropdown-link user-dropdown-menu">
        <a-avatar class="avatar" size="small" icon="user" />
        <span>欢迎您，{{ userInfo.realname }}</span>
      </span>
      <a-menu slot="overlay" class="user-dropdown-menu-wrapper">
        <!-- <a-menu-item key="0">
          <router-link to="/404">
            <a-icon style="margin-right: 8px" type="user" />
            <span>个人中心</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="1">
          <router-link to="/sysManage/setting">
            <a-icon style="margin-right: 8px" type="setting" />
            <span>系统设置</span>
          </router-link>
        </a-menu-item>
        <a-menu-divider /> -->
        <a-menu-item key="2">
          <a href="javascript:;" @click="handleLogout">
            <a-icon style="margin-right: 8px" type="poweroff" />
            <span>退出登录</span>
          </a>
        </a-menu-item>
      </a-menu>
    </a-dropdown>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import HeaderNotice from "@/components/HeaderNotice";
export default {
  name: "UserMenu",
  components: { HeaderNotice },
  methods: {
    ...mapActions(["Logout"]),
    handleLogout() {
      this.Logout();
    },
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
};
</script>
<style lang="less" scoped>
</style>
