var render = function render(){var _vm=this,_c=_vm._self._c;return (
    [
      'input',
      'textarea',
      'date',
      'time',
      'number',
      'radio',
      'checkbox',
      'select',
      'rate',
      'switch',
      'slider',
      'uploadImg',
      'uploadFile',
      'cascader',
      'treeSelect',
    ].includes(_vm.record.type)
  )?_c('a-form-item',{style:(_vm.formConfig.layout === 'horizontal' && _vm.formConfig.labelLayout === 'flex'
      ? { display: 'flex' }
      : {}),attrs:{"label-col":_vm.formConfig.layout === 'horizontal'
      ? _vm.formConfig.labelLayout === 'flex'
        ? { style: `width:${_vm.formConfig.labelWidth}px` }
        : _vm.formConfig.labelCol
      : {},"wrapper-col":_vm.formConfig.layout === 'horizontal'
      ? _vm.formConfig.labelLayout === 'flex'
        ? { style: 'width:auto;flex:1' }
        : _vm.formConfig.wrapperCol
      : {}}},[_c('span',{attrs:{"slot":"label"},slot:"label"},[_c('a-tooltip',[_c('span',{domProps:{"textContent":_vm._s(_vm.record.label)}}),(_vm.record.help)?_c('span',{attrs:{"slot":"title"},domProps:{"innerHTML":_vm._s(_vm.record.help)},slot:"title"}):_vm._e(),(_vm.record.help)?_c('a-icon',{staticClass:"question-circle",attrs:{"type":"question-circle-o"}}):_vm._e()],1)],1),(_vm.record.type === 'textarea')?_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
      _vm.record.model, // input 的 name
      {
        initialValue: _vm.record.options.defaultValue, // 默认值
        rules: _vm.record.rules, // 验证规则
      },
    ]),expression:"[\n      record.model, // input 的 name\n      {\n        initialValue: record.options.defaultValue, // 默认值\n        rules: record.rules, // 验证规则\n      },\n    ]"}],style:(`width:${_vm.record.options.width}`),attrs:{"autoSize":{
      minRows: _vm.record.options.minRows,
      maxRows: _vm.record.options.maxRows,
    },"disabled":_vm.disabled || _vm.record.options.disabled,"placeholder":_vm.record.options.placeholder,"allowClear":_vm.record.options.clearable,"maxLength":_vm.record.options.maxLength,"rows":4},on:{"change":function($event){return _vm.handleChange($event.target.value, _vm.record.model)}}}):(_vm.record.type === 'radio')?_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
      _vm.record.model,
      {
        initialValue: _vm.record.options.defaultValue,
        rules: _vm.record.rules,
      },
    ]),expression:"[\n      record.model,\n      {\n        initialValue: record.options.defaultValue,\n        rules: record.rules,\n      },\n    ]"}],attrs:{"options":!_vm.record.options.dynamic
        ? _vm.record.options.options
        : _vm.dynamicData[_vm.record.options.dynamicKey]
        ? _vm.dynamicData[_vm.record.options.dynamicKey]
        : [],"disabled":_vm.disabled || _vm.record.options.disabled,"placeholder":_vm.record.options.placeholder},on:{"change":function($event){return _vm.handleChange($event.target.value, _vm.record.model)}}}):(_vm.record.type === 'checkbox')?_c('a-checkbox-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
      _vm.record.model,
      {
        initialValue: _vm.record.options.defaultValue,
        rules: _vm.record.rules,
      },
    ]),expression:"[\n      record.model,\n      {\n        initialValue: record.options.defaultValue,\n        rules: record.rules,\n      },\n    ]"}],attrs:{"options":!_vm.record.options.dynamic
        ? _vm.record.options.options
        : _vm.dynamicData[_vm.record.options.dynamicKey]
        ? _vm.dynamicData[_vm.record.options.dynamicKey]
        : [],"disabled":_vm.disabled || _vm.record.options.disabled,"placeholder":_vm.record.options.placeholder},on:{"change":function($event){return _vm.handleChange($event, _vm.record.model)}}}):(_vm.record.type === 'switch')?_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:([
      _vm.record.model,
      {
        initialValue: _vm.record.options.defaultValue,
        valuePropName: 'checked',
        rules: _vm.record.rules,
      },
    ]),expression:"[\n      record.model,\n      {\n        initialValue: record.options.defaultValue,\n        valuePropName: 'checked',\n        rules: record.rules,\n      },\n    ]"}],attrs:{"disabled":_vm.disabled || _vm.record.options.disabled},on:{"change":function($event){return _vm.handleChange($event, _vm.record.model)}}}):(_vm.record.type === 'slider')?_c('div',{staticClass:"slider-box",style:(`width:${_vm.record.options.width}`)},[_c('div',{staticClass:"slider"},[_c('a-slider',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          _vm.record.model,
          {
            initialValue: _vm.record.options.defaultValue,
            rules: _vm.record.rules,
          },
        ]),expression:"[\n          record.model,\n          {\n            initialValue: record.options.defaultValue,\n            rules: record.rules,\n          },\n        ]"}],attrs:{"disabled":_vm.disabled || _vm.record.options.disabled,"min":_vm.record.options.min,"max":_vm.record.options.max,"step":_vm.record.options.step},on:{"change":function($event){return _vm.handleChange($event, _vm.record.model)}}})],1),(_vm.record.options.showInput)?_c('div',{staticClass:"number"},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          _vm.record.model,
          {
            initialValue: _vm.record.options.defaultValue,
            rules: [
              {
                validator: (rule, value, callback) => {
                  if (
                    _vm.record.options.step &&
                    value % _vm.record.options.step !== 0
                  ) {
                    callback('输入值必须是步长的倍数');
                  }
                  callback();
                },
              },
            ],
          },
        ]),expression:"[\n          record.model,\n          {\n            initialValue: record.options.defaultValue,\n            rules: [\n              {\n                validator: (rule, value, callback) => {\n                  if (\n                    record.options.step &&\n                    value % record.options.step !== 0\n                  ) {\n                    callback('输入值必须是步长的倍数');\n                  }\n                  callback();\n                },\n              },\n            ],\n          },\n        ]"}],staticStyle:{"width":"100%"},attrs:{"disabled":_vm.disabled || _vm.record.options.disabled,"min":_vm.record.options.min,"max":_vm.record.options.max,"step":_vm.record.options.step},on:{"change":function($event){return _vm.handleChange($event, _vm.record.model)}}})],1):_vm._e()]):_c(_vm.componentItem,_vm._b({directives:[{name:"decorator",rawName:"v-decorator",value:([
      _vm.record.model, // input 的 name
      {
        initialValue: _vm.record.options.defaultValue, // 默认值
        rules: _vm.record.rules, // 验证规则
      },
    ]),expression:"[\n      record.model, // input 的 name\n      {\n        initialValue: record.options.defaultValue, // 默认值\n        rules: record.rules, // 验证规则\n      },\n    ]"}],tag:"component",style:(`width:${_vm.record.options.width}`),attrs:{"min":_vm.record.options.min || _vm.record.options.min === 0
        ? _vm.record.options.min
        : -Infinity,"max":_vm.record.options.max || _vm.record.options.max === 0
        ? _vm.record.options.max
        : Infinity,"precision":_vm.record.options.precision > 50 ||
      (!_vm.record.options.precision && _vm.record.options.precision !== 0)
        ? null
        : _vm.record.options.precision,"parentDisabled":_vm.disabled || _vm.record.options.disabled,"disabled":_vm.disabled || _vm.record.options.disabled,"record":_vm.record,"config":_vm.config,"filterOption":_vm.record.options.showSearch
        ? (inputValue, option) => {
            return (
              option.componentOptions.children[0].text
                .toLowerCase()
                .indexOf(inputValue.toLowerCase()) >= 0
            );
          }
        : false,"allowClear":_vm.record.options.clearable,"dynamicData":_vm.dynamicData,"treeData":!_vm.record.options.dynamic
        ? _vm.record.options.options
        : _vm.dynamicData[_vm.record.options.dynamicKey]
        ? _vm.dynamicData[_vm.record.options.dynamicKey]
        : [],"options":!_vm.record.options.dynamic
        ? _vm.record.options.options
        : _vm.dynamicData[_vm.record.options.dynamicKey]
        ? _vm.dynamicData[_vm.record.options.dynamicKey]
        : [],"mode":_vm.record.options.multiple ? 'multiple' : ''},on:{"change":function($event){return _vm.handleChange($event, _vm.record.model)}}},'component',_vm.componentOption,false))],1):(['batch', 'editor', 'selectInputList'].includes(_vm.record.type))?_c('a-form-item',{style:(_vm.formConfig.layout === 'horizontal' &&
    _vm.formConfig.labelLayout === 'flex' &&
    _vm.record.options.showLabel
      ? { display: 'flex' }
      : {}),attrs:{"label":!_vm.record.options.showLabel ? '' : _vm.record.label,"label-col":_vm.formConfig.layout === 'horizontal' && _vm.record.options.showLabel
      ? _vm.formConfig.labelLayout === 'flex'
        ? { style: `width:${_vm.formConfig.labelWidth}px` }
        : _vm.formConfig.labelCol
      : {},"wrapper-col":_vm.formConfig.layout === 'horizontal' && _vm.record.options.showLabel
      ? _vm.formConfig.labelLayout === 'flex'
        ? { style: 'width:auto;flex:1' }
        : _vm.formConfig.wrapperCol
      : {}}},[_c(_vm.componentItem,_vm._b({directives:[{name:"decorator",rawName:"v-decorator",value:([
      _vm.record.model, // input 的 name
      {
        initialValue: _vm.record.options.defaultValue, // 默认值
        rules: _vm.record.rules, // 验证规则
      },
    ]),expression:"[\n      record.model, // input 的 name\n      {\n        initialValue: record.options.defaultValue, // 默认值\n        rules: record.rules, // 验证规则\n      },\n    ]"}],ref:['batch', 'selectInputList'].includes(_vm.record.type) && 'KBatch',tag:"component",style:(`width:${_vm.record.options.width}`),attrs:{"record":_vm.record,"config":_vm.config,"parentDisabled":_vm.disabled || _vm.record.options.disabled,"disabled":_vm.disabled || _vm.record.options.disabled,"dynamicData":_vm.dynamicData},on:{"change":function($event){return _vm.handleChange($event, _vm.record.model)}}},'component',_vm.componentOption,false))],1):(_vm.record.type === 'button')?_c('a-form-item',[_c('a-button',{attrs:{"disabled":_vm.disabled || _vm.record.options.disabled,"type":_vm.record.options.type,"html-type":_vm.record.options.handle === 'submit' ? 'submit' : undefined},domProps:{"textContent":_vm._s(_vm.record.label)},on:{"click":function($event){_vm.record.options.handle === 'submit'
        ? false
        : _vm.record.options.handle === 'reset'
        ? _vm.$emit('handleReset')
        : _vm.dynamicData[_vm.record.options.dynamicFun]
        ? _vm.dynamicData[_vm.record.options.dynamicFun]()
        : false}}})],1):(_vm.record.type === 'alert')?_c('a-form-item',[_c('a-alert',{attrs:{"message":_vm.record.label,"description":_vm.record.options.description,"type":_vm.record.options.type,"showIcon":_vm.record.options.showIcon,"closable":_vm.record.options.closable,"banner":_vm.record.options.banner}})],1):(_vm.record.type === 'text')?_c('a-form-item',[_c('div',{style:({ textAlign: _vm.record.options.textAlign })},[_c('label',{class:{ 'ant-form-item-required': _vm.record.options.showRequiredMark },style:({
        fontFamily: _vm.record.options.fontFamily,
        fontSize: _vm.record.options.fontSize,
        color: _vm.record.options.color,
      }),domProps:{"textContent":_vm._s(_vm.record.label)}})])]):(_vm.record.type === 'html')?_c('div',{domProps:{"innerHTML":_vm._s(_vm.record.options.defaultValue)}}):(_vm.customList.includes(_vm.record.type))?_c('customComponent',{attrs:{"record":_vm.record,"disabled":_vm.disabled,"dynamicData":_vm.dynamicData,"formConfig":_vm.formConfig},on:{"change":function($event){return _vm.handleChange($event, _vm.record.model)}}}):_c('div',[(
      _vm.record.type === 'divider' &&
      _vm.record.label !== '' &&
      _vm.record.options.orientation
    )?_c('a-divider',{attrs:{"orientation":_vm.record.options.orientation}},[_vm._v(_vm._s(_vm.record.label))]):(_vm.record.type === 'divider' && _vm.record.label !== '')?_c('a-divider',[_vm._v(_vm._s(_vm.record.label))]):(_vm.record.type === 'divider' && _vm.record.label === '')?_c('a-divider'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }